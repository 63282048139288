<template>
  <div class="vx-auto-suggest">
    <div class="flex items-center relative">
      <!-- Input -->
      <vs-input
        ref="input"
        :placeholder="placeholder"
        :class="inputClassses"
        class="z-50"
        icon-pack="feather"
        icon="icon-search"
        icon-no-border
        v-model="searchQuery"
        @keyup.esc="escPressed"
        @keyup.enter="suggestionSelected"
        @focus="updateInputFocus"
        @blur="updateInputFocus(false)" />
    </div>

    <!-- Group List -->
    <ul ref="scrollContainer"
        :class="{'hidden': !inputFocused}"
        class="auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-x-hidden"
        @mouseenter="insideSuggestions = true"
        @mouseleave="insideSuggestions = false"
        @focus="updateInputFocus"
        @blur="updateInputFocus(false)"
        tabindex="-1">

      <li ref="grp_list"
          v-for="(suggestion_list, grp_name, grp_index) in filteredData"
          :key="grp_index"
          class="auto-suggest__suggestion-group-container">

          <!-- Group Header -->
          <p class="auto-suggest__suggestion-group-title pt-3 pb-1 px-4" v-if="!hideGroupTitle">
            <slot name="group" :group_name="grp_name"></slot>
          </p>

          <!-- Suggestion List of each group -->
          <ul>
            <li
              v-for="(suggestion, index) in suggestion_list"
              :key="index"
              class="auto-suggest__suggestion-group__suggestion py-3 px-4 cursor-pointer"
              :class="{'vx-auto-suggest__current-selected': currentSelected == `${grp_index}.${index}`}"
              @mouseenter="currentSelected = suggestion"
              @click="suggestionSelected">
              <slot :name="grp_name" :suggestion="suggestion"></slot>
            </li>

            <li class="auto-suggest__suggestion-group__suggestion py-3 px-4 no-results" v-if="!suggestion_list.length && searchQuery">
              <slot name="noResult" :group_name="grp_name">
                  <p>Nenhum resultado encontrado.</p>
              </slot>
            </li>
          </ul>
      </li>
      <li class="auto-suggest__suggestion-group__suggestion py-3 px-4 is-fetching" v-if="isFetching">
        <slot name="isFetching"></slot>
      </li>

    </ul>

  </div>
</template>

<script>
// Store Module
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'
import _ from 'lodash'

export default{
  props: {
    placeholder: {
      type: String,
      default: 'Buscar..'
    },
    data: {
      type: Object,
      required: true
    },
    initalData: {
      type: Object,
      default: () => new Object
    },
    inputClassses: {
      type: [String, Object, Array]
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    showPinned: {
      type: Boolean,
      default: false
    },
    searchLimit: {
      type: Number,
      default: 10
    },
    hideGroupTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchQuery: '',
      isFetching: false,
      filteredData: {},
      currentSelected: -1,
      inputFocused: false,
      insideSuggestions: false
    }
  },
  watch: {
    // UPDATE SUGGESTIONS LIST
    searchQuery (val) {
      this.$emit('input', val)

      if (val === '') {
        this.inputInit()
      } else {
        const payload = {
          offset: 0,
          limit: this.searchLimit,
          searchQuery: val,
          params: null
        }
        this.isFetching = true
        this.debouncedGetSearchResult(payload)
      }
    },
    autoFocus (val) {
      if (val) this.focusInput()
      else this.searchQuery = ''
    }
  },
  methods: {
    async getSearchResult (payload) {
      try {
        const students = await this.$store.dispatch('studentManagement/fetchStudents', payload)
        this.isFetching = false
        this.filteredData = {
          alunos: students.data.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    escPressed () {
      this.$emit('closeSearchbar')
      this.searchQuery = ''
    },
    inputInit () {
      if (Object.entries(this.initalData).length === 0 && this.initalData.constructor === Object) {
        this.filteredData = {}
      } else {
        this.filteredData = this.initalData
      }
    },
    updateInputFocus (val = true) {
      if (val) {
        if (this.searchQuery === '') this.inputInit()
        setTimeout(() => {
          this.inputFocused = true
        }, 100)
      } else {
        if (this.insideSuggestions) return
        setTimeout(() => {
          this.inputFocused = false
        }, 100)
        this.escPressed()
      }
    },
    suggestionSelected () {
      this.$emit('selected', this.currentSelected)
      this.searchQuery = ''
    },
    focusInput () {
      this.$refs.input.$el.querySelector('input').focus()
    }
  },
  created () {
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  },
  mounted () {
    if (this.autoFocus) this.focusInput()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxAutoSuggest.scss";
</style>
