/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: null,
    name: 'Attendance',
    icon: 'UsersIcon',
    // tag: '3',
    // tagColor: 'success',
    rule: 'attendance',
    i18n: 'Attendance',
    submenu: [
      {
        url: '/student/list',
        name: 'Students',
        slug: 'students',
        icon: 'UsersIcon',
        i18n: 'Students',
        rule: 'attendance:student:'
      },
      {
        url: '/student-documents',
        name: 'Student Documents',
        slug: 'documents',
        icon: 'FileIcon',
        i18n: 'StudentDocuments',
        rule: 'attendance:student_document'
      },
      {
        url: '/cfc-documents',
        name: 'CFC Documents',
        slug: 'documents',
        icon: 'FileIcon',
        i18n: 'CFCDocuments',
        rule: 'attendance:cfc_document'
      },
      {
        url: '/vehicles',
        name: 'Vehicles',
        slug: 'vehicles',
        featherIcon: false,
        icon: 'directions_car', //mood | sentiment_dissatisfied
        i18n: 'Vehicles',
        rule: 'attendance:vehicle'
      },
      {
        url: '/test-simulation',
        name: 'TestSimulation',
        slug: 'test-simulation',
        featherIcon: false,
        icon: 'FileIcon',
        i18n: 'TestSimulation',
        rule: 'attendance:test_simulation'
      }
    ]
  },

  {
    url: null,
    name: 'Schedules',
    icon: 'CalendarIcon',
    rule: 'schedule',
    // tag: '3',
    // tagColor: 'success',
    i18n: 'Schedules',
    submenu: [
      {
        url: '/schedule/practicalClass',
        name: 'Practical class',
        slug: 'practical-class',
        icon: 'MailIcon',
        tagColor: 'success',
        i18n: 'PracticalClass',
        rule: 'schedule:practical_class'
      },
      {
        url: '/schedule/theoreticalClass',
        name: 'Theoretical Class',
        slug: 'theoretical-class',
        icon: 'MessageSquareIcon',
        i18n: 'TheoreticalClass',
        rule: 'schedule:theoretical_class'
      },
      {
        url: '/schedule/commitments',
        name: 'Commitments',
        slug: 'todo',
        icon: 'CheckSquareIcon',
        i18n: 'Commitments',
        rule: 'schedule:commitment:'
      },
      {
        url: '/schedule/medicalExam',
        name: 'Medical Exam',
        slug: 'medical-exam',
        icon: 'CalendarIcon',
        tagColor: 'success',
        i18n: 'MedicalExam',
        rule: 'schedule:medical_exam:'
      },
      {
        url: '/schedule/psychotechnicalExam',
        name: 'Psychotechnical Exam',
        icon: 'UserIcon',
        slug: 'psychotechnical-exam',
        i18n: 'PsychotechnicalExam',
        rule: 'schedule:psychotechnical_exam:'
      },
      {
        url: '/schedule/theoreticalExam',
        name: 'Theoretical Exam',
        icon: 'UserIcon',
        slug: 'theoretical-exam',
        i18n: 'TheoreticalExam',
        rule: 'schedule:theoretical_exam:'
      },
      {
        url: '/schedule/practicalExam',
        name: 'Practical Exam',
        icon: 'UserIcon',
        slug: 'practical-exam',
        i18n: 'PracticalExam',
        value: 'schedule:practical_exam:'
      },
      {
        url: '/schedule/setting',
        name: 'Setting',
        icon: 'UserIcon',
        slug: 'schedule-setting',
        i18n: 'ScheduleSetting',
        rule: 'schedule:setting:'
      }
    ]
  },
  {
    url: null,
    name: 'Financial',
    rule: 'financial',
    icon: 'DollarSignIcon',
    i18n: 'Financial',
    submenu: [
      {
        url: '/financial/general-cash',
        name: 'General Cash',
        icon: 'UserIcon',
        slug: 'general-cash',
        i18n: 'GeneralCash',
        rule: 'financial:general_cash'
      },
      {
        url: '/financial/my-cashier',
        name: 'My Cash',
        icon: 'UserIcon',
        slug: 'my-cash',
        i18n: 'MyCashier',
        rule: 'my_cashier'
      },
      {
        url: '/remessa-boleto',
        name: 'Remessa Boleto',
        icon: 'UserIcon',
        slug: 'remessa-boleto',
        i18n: 'RemessaBoleto',
        rule: 'financial:delivery_bankslip'
      },
      {
        url: '/financial/account/list',
        name: 'Accounts',
        icon: 'UserIcon',
        slug: 'accounts',
        i18n: 'Accounts',
        rule: 'financial:account'
      },
      {
        url: '/internal-service-order',
        name: 'Internal Service Order',
        icon: 'DollarSignIcon',
        slug: 'internal-service-order',
        i18n: 'InternalServiceOrder',
        rule: 'financial:service_order'
      },
      {
        url: '/receipts',
        name: 'Receipts',
        icon: 'InboxIcon',
        i18n: 'Receipts',
        slug: 'receipts',
        rule: 'financial:receipt'
      },
      {
        url: '/receivable-from-cards',
        name: 'Receivable From Cards',
        icon: 'InboxIcon',
        i18n: 'ReceivableFromCards',
        slug: 'receivable-from-cards',
        rule: 'financial:card_receipt'
      },
      {
        url: '/financial-register',
        name: 'Registers to financial',
        icon: 'InboxIcon',
        i18n: 'FinancialRegister',
        slug: 'financial-register',
        rule: 'financial:setting:'
      }
    ]
  },
  {
    url: '/reports',
    name: 'Reports',
    rule: 'report',
    icon: 'ClipboardIcon',
    i18n: 'Reports'
  },
  {
    url: '/mailMarketing',
    name: 'Mail Marketing',
    rule: 'mail_marketing',
    slug: 'mail-marketing',
    icon: 'MailIcon',
    i18n: 'MailMarketing'
  },
  {
    url: '/registrations',
    name: 'Registrations',
    rule: 'register',
    slug: 'registrations',
    icon: 'ArchiveIcon',
    i18n: 'Registrations'
  },
  // {
  //   url: '/charts-and-maps/charts/chartjs',
  //   name: 'Charts',
  //   slug: 'Charts',
  //   icon: 'PieChartIcon',
  //   i18n: 'Charts'
  // },
  {
    url: '/configurations',
    name: 'Configurations',
    rule: 'configuration',
    icon: 'SettingsIcon',
    i18n: 'Configurations'
  },
  // {
  //   url: '/charts-and-maps/charts/echarts',
  //   name: 'Video Tutorials',
  //   slug: 'video-tutorials',
  //   icon: 'VideoIcon',
  //   i18n: 'VideoTutorials'
  // },
  {
    url: '/audit',
    name: 'Audit',
    rule: 'audit:',
    slug: 'audit',
    icon: 'LockIcon',
    i18n: 'Audit'
  },
  {
    url: '/logout',
    name: 'Logout',
    slug: 'logout',
    icon: 'LogOutIcon',
    i18n: 'Logout'
  }
  /**
   * ACCOUNTPLANS E SERVICES FOI INSERIDO DENTRO DE ABAS EM CONFIGURAÇÕES E
   * POR TANTO REMOVIDO DO MENU, PORÉM MANTIVE OS ARQUIVOS DUPLICADOS NA ARVORE DE DIRETÓRIOS CASO
   * SEJA NECESSÁRIO. PASSADO ALGUM TEMPO DEVO EXCLUIR ESTES ARQUIVOS
   */
  // {
  //   url: '/financial/accountPlans',
  //   name: 'Account Plans',
  //   icon: 'MapIcon',
  //   slug: 'account-plans',
  //   i18n: 'AccountPlans'
  // },
  // {
  //   url: '/services',
  //   name: 'Serviços',
  //   icon: 'MapIcon',
  //   slug: 'services',
  //   i18n: 'Services'
  // },

  ////////////////////////////////////////////////////////////////////////////////////////////////

//   {
//     header: 'Attendance',
//     icon: 'HomeIcon',
//     i18n: 'Attendance',
//     items: [
//       {
//         url: '/student/list',
//         name: 'Students',
//         slug: 'students',
//         icon: 'UsersIcon',
//         i18n: 'Students'
//       },
//       {
//         url: '/student/documents',
//         name: 'Student Documents',
//         slug: 'documents',
//         icon: 'FileIcon',
//         i18n: 'StudentDocuments'
//       }
//     ]
//   },
//   {
//     header: 'Schedules',
//     icon: 'CalendarIcon',
//     i18n: 'Schedules',
//     items: [
//       {
//         url: '/schedule/practicalClass',
//         name: 'Practical class',
//         slug: 'practical-class',
//         icon: 'MailIcon',
//         tagColor: 'success',
//         i18n: 'PracticalClass'
//       },
//       {
//         url: '/schedule/theoreticalClass',
//         name: 'Theoretical Class',
//         slug: 'theoretical-class',
//         icon: 'MessageSquareIcon',
//         i18n: 'TheoreticalClass'
//       },
//       {
//         url: '/schedule/commitments',
//         name: 'Commitments',
//         slug: 'todo',
//         icon: 'CheckSquareIcon',
//         i18n: 'Commitments'
//       },
//       {
//         url: '/schedule/medicalExam',
//         name: 'Medical Exam',
//         slug: 'medical-exam',
//         icon: 'CalendarIcon',
//         tagColor: 'success',
//         i18n: 'MedicalExam'
//       },
//       {
//         url: '/schedule/practicalExam',
//         name: 'Practical Exam',
//         icon: 'UserIcon',
//         slug: 'practical-exam',
//         i18n: 'PracticalExam'
//       },
//       // {
//       //   url: '/apps/eCommerce/shop',
//       //   name: 'Practical Exam',
//       //   slug: 'practical-exam',
//       //   icon: 'ShoppingCartIcon',
//       //   i18n: 'PracticalExam'
//       //   submenu: [
//       //     {
//       //       url: '/apps/eCommerce/shop',
//       //       name: 'Shop',
//       //       slug: 'ecommerce-shop',
//       //       i18n: 'Shop'
//       //     },
//       //     {
//       //       url: '/apps/eCommerce/item/',
//       //       name: 'Item Details',
//       //       slug: 'ecommerce-item-detail-view',
//       //       i18n: 'ItemDetails'
//       //     },
//       //     {
//       //       url: '/apps/eCommerce/wish-list',
//       //       name: 'Wish List',
//       //       slug: 'ecommerce-wish-list',
//       //       i18n: 'WishList'
//       //     },
//       //     {
//       //       url: '/apps/eCommerce/checkout',
//       //       name: 'Checkout',
//       //       slug: 'ecommerce-checkout',
//       //       i18n: 'Checkout'
//       //     }
//       //   ]
//       // },
//       {
//         url: '/schedule/psychotechnicalExam',
//         name: 'Psychotechnical Exam',
//         icon: 'UserIcon',
//         slug: 'psychotechnical-exam',
//         i18n: 'PsychotechnicalExam'
//         // submenu: [
//         //   {
//         //     url: '/apps/user/user-list',
//         //     name: 'List',
//         //     slug: 'app-user-list',
//         //     i18n: 'List'
//         //   },
//         //   {
//         //     url: '/apps/user/user-view/268',
//         //     name: 'View',
//         //     slug: 'app-user-view',
//         //     i18n: 'View'
//         //   },
//         //   {
//         //     url: '/apps/user/user-edit/268',
//         //     name: 'Edit',
//         //     slug: 'app-user-edit',
//         //     i18n: 'Edit'
//         //   }
//         // ]
//       },
//       {
//         url: '/schedule/theoreticalExam',
//         name: 'Theoretical Exam',
//         icon: 'UserIcon',
//         slug: 'theoretical-exam',
//         i18n: 'TheoreticalExam'
//       }
//     ]
//   },
//   {
//     header: 'Financeiro',
//     icon: 'DollarSignIcon',
//     i18n: 'Financial',
//     items: [
//       {
//         url: '/financial/general-cash',
//         name: 'General Cash',
//         icon: 'UserIcon',
//         slug: 'general-cash',
//         i18n: 'GeneralCash'
//       },
//       {
//         url: '/financial/my-cashier',
//         name: 'My Cash',
//         icon: 'UserIcon',
//         slug: 'my-cash',
//         i18n: 'MyCashier'
//       },
//       {
//         url: '/ui-elements/grid/vuesax',
//         name: 'Remessa Boleto',
//         icon: 'UserIcon',
//         slug: 'remessa-boleto',
//         i18n: 'RemessaBoleto'
//       },
//       {
//         url: '/ui-elements/grid/tailwind',
//         name: 'Retorno Boleto',
//         icon: 'UserIcon',
//         slug: 'retorno-boleto',
//         i18n: 'RetornoBoleto'
//       },
//       {
//         url: '/financial/account/list',
//         name: 'Accounts',
//         icon: 'UserIcon',
//         slug: 'accounts',
//         i18n: 'Accounts'
//       }

//       // {
//       //   url: '/ui-elements/colors',
//       //   name: 'Colors',
//       //   slug: 'colors',
//       //   icon: 'DropletIcon',
//       //   i18n: 'Colors'
//       // },
//       // {
//       //   url: null,
//       //   name: 'Card',
//       //   icon: 'CreditCardIcon',
//       //   i18n: 'Card',
//       //   submenu: [
//       //     {
//       //       url: '/ui-elements/card/basic',
//       //       name: 'Basic',
//       //       slug: 'basic-cards',
//       //       i18n: 'Basic'
//       //     },
//       //     {
//       //       url: '/ui-elements/card/statistics',
//       //       name: 'Statistics',
//       //       slug: 'statistics-cards',
//       //       i18n: 'Statistics'
//       //     },
//       //     {
//       //       url: '/ui-elements/card/analytics',
//       //       name: 'Analytics',
//       //       slug: 'analytics-cards',
//       //       i18n: 'Analytics'
//       //     },
//       //     {
//       //       url: '/ui-elements/card/card-actions',
//       //       name: 'Card Actions',
//       //       slug: 'card-actions',
//       //       i18n: 'CardActions'
//       //     },
//       //     {
//       //       url: '/ui-elements/card/card-colors',
//       //       name: 'Card Colors',
//       //       slug: 'card-colors',
//       //       i18n: 'CardColors'
//       //     }
//       //   ]
//       // },
//       // {
//       //   url: null,
//       //   name: 'Components',
//       //   icon: 'ArchiveIcon',
//       //   i18n: 'Components',
//       //   submenu: [
//       //     {
//       //       url: '/components/alert',
//       //       name: 'Alert',
//       //       slug: 'component-alert',
//       //       i18n: 'Alert'
//       //     },
//       //     {
//       //       url: '/components/avatar',
//       //       name: 'Avatar',
//       //       slug: 'component-avatar',
//       //       i18n: 'Avatar'
//       //     },
//       //     {
//       //       url: '/components/breadcrumb',
//       //       name: 'Breadcrumb',
//       //       slug: 'component-breadcrumb',
//       //       i18n: 'Breadcrumb'
//       //     },
//       //     {
//       //       url: '/components/button',
//       //       name: 'Button',
//       //       slug: 'component-button',
//       //       i18n: 'Button'
//       //     },
//       //     {
//       //       url: '/components/button-group',
//       //       name: 'Button Group',
//       //       slug: 'component-button-group',
//       //       i18n: 'ButtonGroup'
//       //     },
//       //     {
//       //       url: '/components/chip',
//       //       name: 'Chip',
//       //       slug: 'component-chip',
//       //       i18n: 'Chip'
//       //     },
//       //     {
//       //       url: '/components/collapse',
//       //       name: 'Collapse',
//       //       slug: 'component-collapse',
//       //       i18n: 'Collapse'
//       //     },
//       //     {
//       //       url: '/components/dialogs',
//       //       name: 'Dialogs',
//       //       slug: 'component-dialog',
//       //       i18n: 'Dialogs'
//       //     },
//       //     {
//       //       url: '/components/divider',
//       //       name: 'Divider',
//       //       slug: 'component-divider',
//       //       i18n: 'Divider'
//       //     },
//       //     {
//       //       url: '/components/dropdown',
//       //       name: 'DropDown',
//       //       slug: 'component-drop-down',
//       //       i18n: 'DropDown'
//       //     },
//       //     {
//       //       url: '/components/list',
//       //       name: 'List',
//       //       slug: 'component-list',
//       //       i18n: 'List'
//       //     },
//       //     {
//       //       url: '/components/loading',
//       //       name: 'Loading',
//       //       slug: 'component-loading',
//       //       i18n: 'Loading'
//       //     },
//       //     {
//       //       url: '/components/navbar',
//       //       name: 'Navbar',
//       //       slug: 'component-navbar',
//       //       i18n: 'Navbar'
//       //     },
//       //     {
//       //       url: '/components/notifications',
//       //       name: 'Notifications',
//       //       slug: 'component-notifications',
//       //       i18n: 'Notifications'
//       //     },
//       //     {
//       //       url: '/components/pagination',
//       //       name: 'Pagination',
//       //       slug: 'component-pagination',
//       //       i18n: 'Pagination'
//       //     },
//       //     {
//       //       url: '/components/popup',
//       //       name: 'Popup',
//       //       slug: 'component-popup',
//       //       i18n: 'Popup'
//       //     },
//       //     {
//       //       url: '/components/progress',
//       //       name: 'Progress',
//       //       slug: 'component-progress',
//       //       i18n: 'Progress'
//       //     },
//       //     {
//       //       url: '/components/sidebar',
//       //       name: 'Sidebar',
//       //       slug: 'component-sidebar',
//       //       i18n: 'Sidebar'
//       //     },
//       //     {
//       //       url: '/components/slider',
//       //       name: 'Slider',
//       //       slug: 'component-slider',
//       //       i18n: 'Slider'
//       //     },
//       //     {
//       //       url: '/components/tabs',
//       //       name: 'Tabs',
//       //       slug: 'component-tabs',
//       //       i18n: 'Tabs'
//       //     },
//       //     {
//       //       url: '/components/tooltip',
//       //       name: 'Tooltip',
//       //       slug: 'component-tooltip',
//       //       i18n: 'Tooltip'
//       //     },
//       //     {
//       //       url: '/components/upload',
//       //       name: 'Upload',
//       //       slug: 'component-upload',
//       //       i18n: 'Upload'
//       //     }
//       //   ]
//       // },
//       // {
//       //   url: null,
//       //   name: 'Extensions',
//       //   icon: 'PlusCircleIcon',
//       //   i18n: 'Extensions',
//       //   submenu: [
//       //     {
//       //       url: '/extensions/select',
//       //       name: 'Select',
//       //       icon: 'PocketIcon',
//       //       slug: 'extra-component-select',
//       //       i18n: 'Select'
//       //     },
//       //     {
//       //       url: '/extensions/quill-editor',
//       //       name: 'Quill Editor',
//       //       icon: 'EditIcon',
//       //       slug: 'extra-component-quill-editor',
//       //       i18n: 'QuillEditor'
//       //     },
//       //     {
//       //       url: '/extensions/drag-and-drop',
//       //       name: 'Drag & Drop',
//       //       icon: 'DropletIcon',
//       //       slug: 'extra-component-drag-and-drop',
//       //       i18n: 'DragAndDrop'
//       //     },
//       //     {
//       //       url: '/extensions/datepicker',
//       //       name: 'Datepicker',
//       //       icon: 'CalendarIcon',
//       //       slug: 'extra-component-datepicker',
//       //       i18n: 'Datepicker'
//       //     },
//       //     {
//       //       url: '/extensions/datetime-picker',
//       //       name: 'Datetime Picker',
//       //       icon: 'ClockIcon',
//       //       slug: 'extra-component-datetime-picker',
//       //       i18n: 'DatetimePicker'
//       //     },
//       //     {
//       //       url: '/extensions/access-control',
//       //       name: 'Access Control',
//       //       slug: 'extra-component-access-control',
//       //       i18n: 'AccessControl'
//       //     },
//       //     {
//       //       url: '/extensions/i18n',
//       //       name: 'I18n',
//       //       slug: 'extra-component-i18n',
//       //       i18n: 'I18n'
//       //     },
//       //     {
//       //       url: '/extensions/carousel',
//       //       name: 'Carousel',
//       //       icon: 'LayersIcon',
//       //       slug: 'extra-component-carousel',
//       //       i18n: 'Carousel'
//       //     },
//       //     {
//       //       url: '/extensions/clipboard',
//       //       name: 'Clipboard',
//       //       icon: 'CopyIcon',
//       //       slug: 'extra-component-clipboard',
//       //       i18n: 'Clipboard'
//       //     },
//       //     {
//       //       url: '/extensions/context-menu',
//       //       name: 'Context Menu',
//       //       icon: 'MoreHorizontalIcon',
//       //       slug: 'extra-component-context-menu',
//       //       i18n: 'ContextMenu'
//       //     },
//       //     {
//       //       url: '/extensions/star-ratings',
//       //       name: 'Star Ratings',
//       //       icon: 'StarIcon',
//       //       slug: 'extra-component-star-ratings',
//       //       i18n: 'StarRatings'
//       //     },
//       //     {
//       //       url: '/extensions/autocomplete',
//       //       name: 'Autocomplete',
//       //       icon: 'Edit3Icon',
//       //       slug: 'extra-component-autocomplete',
//       //       i18n: 'Autocomplete'
//       //     },
//       //     {
//       //       url: '/extensions/tree',
//       //       name: 'Tree',
//       //       icon: 'GitPullRequestIcon',
//       //       slug: 'extra-component-tree',
//       //       i18n: 'Tree'
//       //     },
//       // {
//       //   name: "Import/Export",
//       //   i18n: "Import/Export",
//       //   submenu: [

//       //   ]
//       // },
//       // {
//       //   url: '/import-export/import',
//       //   name: 'Import',
//       //   icon: 'HomeIcon',
//       //   slug: 'import',
//       //   i18n: 'Import'
//       // },
//       // {
//       //   url: '/import-export/export',
//       //   name: 'Export',
//       //   icon: 'HomeIcon',
//       //   slug: 'export',
//       //   i18n: 'Export'
//       // },
//       // {
//       //   url: '/import-export/export-selected',
//       //   name: 'Export Selected',
//       //   icon: 'HomeIcon',
//       //   slug: 'export-selected',
//       //   i18n: 'ExportSelected'
//       // }
//       // ]
//       // }
//     ]
//   },
//   {
//     header: 'Reports',
//     icon: 'Edit3Icon',
//     i18n: 'Reports',
//     items: [
//       // {
//       //   url: null,
//       //   name: 'Form Elements',
//       //   icon: 'CopyIcon',
//       //   i18n: 'FormElements',
//       //   submenu: [
//       //     {
//       //      url: '/forms/form-elements/select',
//       //      name: "Select",
//       //      slug: "form-element-select",
//       //      i18n: "Select",
//       //     },
//       //     {
//       //       url: '/forms/form-elements/switch',
//       //       name: 'Switch',
//       //       slug: 'form-element-switch',
//       //       i18n: 'Switch'
//       //     },
//       //     {
//       //       url: '/forms/form-elements/checkbox',
//       //       name: 'Checkbox',
//       //       slug: 'form-element-checkbox',
//       //       i18n: 'Checkbox'
//       //     },
//       //     {
//       //       url: '/forms/form-elements/radio',
//       //       name: 'Radio',
//       //       slug: 'form-element-radio',
//       //       i18n: 'Radio'
//       //     },
//       //     {
//       //       url: '/forms/form-elements/input',
//       //       name: 'Input',
//       //       slug: 'form-element-input',
//       //       i18n: 'Input'
//       //     },
//       //     {
//       //       url: '/forms/form-elements/number-input',
//       //       name: 'Number Input',
//       //       slug: 'form-element-number-input',
//       //       i18n: 'NumberInput'
//       //     },
//       //     {
//       //       url: '/forms/form-elements/textarea',
//       //       name: 'Textarea',
//       //       slug: 'form-element-textarea',
//       //       i18n: 'Textarea'
//       //     }
//       //   ]
//       // },
//       {
//         url: '/forms/form-layouts',
//         name: 'Student (Simplified)',
//         icon: 'PackageIcon',
//         slug: 'reports-student-simplified',
//         i18n: 'StudentSimplified'
//       },
//       {
//         url: '/forms/form-wizard',
//         name: 'Student (Complete)',
//         icon: 'PackageIcon',
//         slug: 'reports-student-complete',
//         i18n: 'StudentComplete'
//       },
//       {
//         url: '/forms/form-validation',
//         name: 'Billets Issued',
//         icon: 'CheckCircleIcon',
//         slug: 'reports-billets-issued',
//         i18n: 'BilletsIssued'
//       },
//       {
//         url: '/forms/form-input-group',
//         name: 'Overdue CNH',
//         icon: 'MenuIcon',
//         slug: 'reports-overdue-cnh',
//         i18n: 'OverdueCNH'
//       },
//       {
//         url: '/ui-elements/table',
//         name: 'Contracts',
//         slug: 'reports-contracts',
//         icon: 'GridIcon',
//         i18n: 'Contracts'
//       },
//       {
//         url: '/ui-elements/ag-grid-table',
//         name: 'Chargebacks (financial)',
//         slug: 'reports-chargebacks',
//         icon: 'GridIcon',
//         i18n: 'ChargebacksFinancial'
//       },
//       {
//         url: '/ui-elements/ag-grid-table',
//         name: 'Financial',
//         slug: 'reports-financial',
//         icon: 'GridIcon',
//         i18n: 'Financial'
//       }
//     ]
//   },
//   {
//     header: 'CFC Documents',
//     icon: 'FileIcon',
//     i18n: 'CFCDocuments',
//     items: [
//       {
//         url: '/pages/profile',
//         slug: 'documents-cfc-cnh-issuance-delivery',
//         name: 'CNH Issuance / Delivery',
//         icon: 'UserIcon',
//         i18n: 'CNHIssuanceDelivery'
//       },
//       {
//         url: '/pages/user-settings',
//         slug: 'documents-exams',
//         name: 'Exams',
//         icon: 'SettingsIcon',
//         i18n: 'Exams'
//       },
//       {
//         url: '/pages/faq',
//         slug: 'documents-new-protocol',
//         name: 'New Protocol',
//         icon: 'HelpCircleIcon',
//         i18n: 'NewProtocol'
//       },
//       {
//         url: '/pages/knowledge-base',
//         slug: 'documents-protocols-issued',
//         name: 'Protocols (Issued)',
//         icon: 'InfoIcon',
//         i18n: 'ProtocolsIssued'
//       },
//       {
//         url: '/pages/search',
//         slug: 'documents-requirement-ladv',
//         name: 'Requirement LADV',
//         icon: 'SearchIcon',
//         i18n: 'RequirementLADV'
//       }
//     ]
//   },
//   {
//     url: '/vehicles',
//     name: 'Vehicles',
//     slug: 'vehicles',
//     featherIcon: false,
//     icon: 'directions_car', //mood | sentiment_dissatisfied
//     i18n: 'Vehicles'
//   },
//   {
//     url: 'charts-and-maps/charts/chartjs',
//     name: 'Mail Marketing',
//     slug: 'mail-marketing',
//     icon: 'PieChartIcon',
//     i18n: 'MailMarketing'
//   },
//   {
//     url: '/registrations',
//     name: 'Registrations',
//     slug: 'registrations',
//     icon: 'PieChartIcon',
//     i18n: 'Registrations'
//   },
//   {
//     header: 'Configurations',
//     icon: 'SettingsIcon',
//     i18n: 'Configurations',
//     items: [
//       {
//         url: null,
//         name: 'Charts',
//         icon: 'PieChartIcon',
//         tag: '3',
//         tagColor: 'success',
//         i18n: 'Charts',
//         submenu: [
//           {
//             url: '/charts-and-maps/charts/apex-charts',
//             name: 'Apex Charts',
//             slug: 'extra-component-charts-apex-charts',
//             i18n: 'ApexCharts'
//           },
//           {
//             url: '/charts-and-maps/charts/chartjs',
//             name: 'chartjs',
//             slug: 'extra-component-charts-chartjs',
//             i18n: 'chartjs'
//           },
//           {
//             url: '/charts-and-maps/charts/echarts',
//             name: 'echarts',
//             slug: 'extra-component-charts-echarts',
//             i18n: 'echarts'
//           }
//         ]
//       },
//       {
//         url: '/financial/accountPlans',
//         name: 'Account Plans',
//         icon: 'MapIcon',
//         slug: 'account-plans',
//         i18n: 'AccountPlans'
//       },
//       {
//         url: '/services',
//         name: 'Serviços',
//         icon: 'MapIcon',
//         slug: 'services',
//         i18n: 'Services'
//       }
//     ]
//   },
//   {
//     url: '/charts-and-maps/charts/echarts',
//     name: 'Video Tutorials',
//     slug: 'video-tutorials',
//     icon: 'PieChartIcon',
//     i18n: 'VideoTutorials'
//   },
//   {
//     url: '/logout',
//     name: 'Logout',
//     slug: 'logout',
//     icon: 'LogOutIcon',
//     i18n: 'Logout'
//   }
]
