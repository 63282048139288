var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "layout--main",
      class: [
        _vm.layoutTypeClass,
        _vm.navbarClasses,
        _vm.footerClasses,
        { "no-scroll": _vm.isAppPage },
      ],
    },
    [
      !_vm.disableThemeTour &&
      _vm.windowWidth >= 1200 &&
      _vm.mainLayoutType === "vertical" &&
      _vm.verticalNavMenuWidth == "default"
        ? _c("vx-tour", { attrs: { steps: _vm.steps } })
        : _vm._e(),
      !_vm.disableCustomizer
        ? _c("the-customizer", {
            attrs: {
              footerType: _vm.footerType,
              hideScrollToTop: _vm.hideScrollToTop,
              navbarType: _vm.navbarType,
              navbarColor: _vm.navbarColor,
              routerTransition: _vm.routerTransition,
            },
            on: {
              toggleHideScrollToTop: _vm.toggleHideScrollToTop,
              updateFooter: _vm.updateFooter,
              updateNavbar: _vm.updateNavbar,
              updateNavbarColor: _vm.updateNavbarColor,
              updateRouterTransition: _vm.updateRouterTransition,
            },
          })
        : _vm._e(),
      _c("v-nav-menu", {
        attrs: {
          navMenuItems: _vm.navMenuItems,
          title: "SIGCFC",
          parent: ".layout--main",
        },
      }),
      _c(
        "div",
        {
          class: [_vm.contentAreaClass, { "show-overlay": _vm.bodyOverlay }],
          attrs: { id: "content-area" },
        },
        [
          _c("div", { attrs: { id: "content-overlay" } }),
          _vm.mainLayoutType === "horizontal" && _vm.windowWidth >= 1200
            ? [
                _c("the-navbar-horizontal", {
                  class: [
                    { "text-white": _vm.isNavbarDark && !_vm.isThemeDark },
                    { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                  ],
                  attrs: { navbarType: _vm.navbarType },
                }),
                _vm.navbarType === "static"
                  ? _c("div", { staticStyle: { height: "62px" } })
                  : _vm._e(),
                _c("h-nav-menu", {
                  class: [
                    { "text-white": _vm.isNavbarDark && !_vm.isThemeDark },
                    { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                  ],
                  attrs: { navMenuItems: _vm.navMenuItems },
                }),
              ]
            : [
                _c("the-navbar-vertical", {
                  class: [
                    { "text-white": _vm.isNavbarDark && !_vm.isThemeDark },
                    { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                  ],
                  attrs: { navbarColor: _vm.navbarColor },
                }),
              ],
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "router-view" }, [
              _c(
                "div",
                { staticClass: "router-content" },
                [
                  _c("transition", { attrs: { name: _vm.routerTransition } }, [
                    _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "router-header flex flex-wrap items-center mb-6",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "content-area__heading",
                                class: {
                                  "pr-4 border-0 md:border-r border-solid border-grey-light":
                                    _vm.$route.meta.breadcrumb,
                                },
                              },
                              [
                                _c("h2", { staticClass: "mb-1" }, [
                                  _vm._v(_vm._s(_vm.routeTitle)),
                                ]),
                              ]
                            ),
                            _vm.$route.meta.breadcrumb
                              ? _c("vx-breadcrumb", {
                                  staticClass: "ml-4 md:block hidden",
                                  attrs: {
                                    route: _vm.$route,
                                    student: _vm.student.name,
                                    isRTL: _vm.$vs.rtl,
                                  },
                                })
                              : _vm._e(),
                            _vm.$route.name === "student-edit" &&
                            _vm.student.name
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "leading-none font-medium ml-auto flex flex-wrap items-center",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "InfoIcon",
                                        svgClasses: "w-5 h-5",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                Última alteração: " +
                                        _vm._s(
                                          _vm.student.responsible_registration
                                            .name
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.formatDateTime(
                                            _vm.student.updated_at,
                                            "dateTimeNoSecond"
                                          )
                                        ) +
                                        "h\n              "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$route.name === "student-edit" &&
                            _vm.student &&
                            _vm.student.phones &&
                            _vm.student.phones.find(
                              (phone) => phone.type === "mobile"
                            ) &&
                            _vm.student.phones.find(
                              (phone) => phone.type === "mobile"
                            ).phone
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ml-4 cursor-pointer flex flex-wrap items-center",
                                    attrs: {
                                      href:
                                        "https://api.whatsapp.com/send?phone=55" +
                                        _vm.celular.slice(0, 2) +
                                        _vm.celular.slice(3) +
                                        "&text=",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      key: "whatsappImg",
                                      staticClass: "mt-0",
                                      attrs: {
                                        src: _vm.whatsappIcon,
                                        alt: "Whatsapp",
                                        width: "32",
                                        height: "32",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.name === "student-edit" && _vm.student
                              ? _c(
                                  "vs-dropdown",
                                  {
                                    ref: "drop",
                                    staticClass: "ml-4 cursor-pointer",
                                    attrs: {
                                      "vs-custom-content": "",
                                      "vs-trigger-click": "",
                                    },
                                  },
                                  [
                                    _c("vs-button", {
                                      attrs: {
                                        id: "reminder_button",
                                        type: _vm.reminder ? "filled" : "flat",
                                        radius: "",
                                        icon: "attach_file",
                                        "icon-pack": "material-icons",
                                      },
                                    }),
                                    _c(
                                      "vs-dropdown-menu",
                                      {
                                        ref: "menu",
                                        staticClass: "w-80",
                                        staticStyle: {
                                          "z-index": "999999999999",
                                        },
                                      },
                                      [
                                        _c("h4", [_vm._v("Lembrete")]),
                                        _c("vs-textarea", {
                                          staticClass: "mb-0",
                                          model: {
                                            value: _vm.reminder,
                                            callback: function ($$v) {
                                              _vm.reminder = $$v
                                            },
                                            expression: "reminder",
                                          },
                                        }),
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "mt-0 w-full",
                                            attrs: {
                                              type: "line",
                                              color: "success",
                                            },
                                            on: { click: _vm.saveReminder },
                                          },
                                          [_c("span", [_vm._v("Salvar")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content-area__content" },
                    [
                      !_vm.hideScrollToTop
                        ? _c(
                            "back-to-top",
                            {
                              attrs: {
                                bottom: "5%",
                                right: _vm.$vs.rtl
                                  ? "calc(100% - 2.2rem - 38px)"
                                  : "30px",
                                visibleoffset: "500",
                              },
                            },
                            [
                              _c("vs-button", {
                                staticClass: "shadow-lg btn-back-to-top",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-arrow-up",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "transition",
                        {
                          attrs: { name: _vm.routerTransition, mode: "out-in" },
                        },
                        [
                          _c("router-view", {
                            on: {
                              changeRouteTitle: _vm.changeRouteTitle,
                              setAppClasses: (classesStr) =>
                                _vm.$emit("setAppClasses", classesStr),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("the-footer"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }