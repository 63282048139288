<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.name">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.name ? activeUserInfo.name  : '' }}</p>
      <small>Online</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <!-- Não uso o avatar pq tem padding -->
        <!-- <vs-avatar :src="activeUserInfo.photo && activeUserInfo.photo.url" key="onlineImg" alt="user-img" class="rounded-full shadow-md cursor-pointer block" size="40px" /> -->
        <img key="onlineImg" :src="activeUserInfo.photo ? activeUserInfo.photo.url : noUserPhoto" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Perfil</span>
          </li>

          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/email').catch(() => {})">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li> -->

          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/todo').catch(() => {})">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li> -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/chat').catch(() => {})">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" :badge="Number.isInteger(activeUserInfo.unreadChat) ? activeUserInfo.unreadChat : 0"/>
            <span class="ml-2">Chat</span>
          </li>

          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li> -->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Sair</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>

export default {
  data () {
    return {
      noUserPhoto: require('@/assets/images/user/material-design-user-icon-29.jpg')
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
        .then(() => {

          this.$vs.loading.close()

          if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken')
          }

          // Change role on logout. Same value as initialRole of acj.js
          this.$acl.change('admin')
          localStorage.removeItem('userInfo')

          // Recarrega a página atual sem usar o cache
          document.location.reload() // ESTOU USANDO ISSO PARA ESVAZIAR O STATE DO VUEX FORÇANDO UM RELOAD

          // This is just for demo Purpose. If user clicks on logout -> redirect
          // this.$router.push('/login').catch(() => {})
        })
        .catch(() => {})
    }
  }
}
</script>
