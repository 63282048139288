var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navbar-bookmarks flex items-center" }, [
    _c(
      "ul",
      { staticClass: "vx-navbar__starred-pages" },
      [
        _c(
          "draggable",
          {
            staticClass: "flex cursor-move",
            attrs: { group: { name: "pinList" } },
            model: {
              value: _vm.starredPagesLimited,
              callback: function ($$v) {
                _vm.starredPagesLimited = $$v
              },
              expression: "starredPagesLimited",
            },
          },
          [
            _vm._l(_vm.starredPagesLimited, function (page) {
              return _c(
                "li",
                { key: page.url, staticClass: "starred-page" },
                [
                  _c(
                    "vx-tooltip",
                    {
                      attrs: {
                        text: page.title,
                        position: "bottom",
                        delay: ".3s",
                      },
                    },
                    [
                      page.title === "Chat"
                        ? _c("feather-icon", {
                            staticClass: "p-2 cursor-pointer",
                            attrs: {
                              badge: Number.isInteger(_vm.user.unreadChat)
                                ? _vm.user.unreadChat
                                : 0,
                              svgClasses: [
                                "h-6 w-6 stroke-current",
                                _vm.textColor,
                              ],
                              icon: page.icon,
                            },
                            on: {
                              click: function ($event) {
                                _vm.$router.push(page.url).catch(() => {})
                              },
                            },
                          })
                        : _c("feather-icon", {
                            staticClass: "p-2 cursor-pointer",
                            attrs: {
                              svgClasses: [
                                "h-6 w-6 stroke-current",
                                _vm.textColor,
                              ],
                              icon: page.icon,
                            },
                            on: {
                              click: function ($event) {
                                _vm.$router.push(page.url).catch(() => {})
                              },
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _c(
              "li",
              { staticClass: "starred-page" },
              [
                _c(
                  "vx-tooltip",
                  {
                    attrs: { text: "Alunos", position: "bottom", delay: ".3s" },
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "p-2 cursor-pointer",
                      attrs: {
                        svgClasses: ["h-6 w-6 stroke-current", _vm.textColor],
                        icon: "UsersIcon",
                      },
                      on: {
                        click: function ($event) {
                          _vm.$router.push("/student/list").catch(() => {})
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "starred-page" },
              [
                _c(
                  "vx-tooltip",
                  {
                    attrs: { text: "Caixa", position: "bottom", delay: ".3s" },
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "p-2 cursor-pointer",
                      attrs: {
                        svgClasses: ["h-6 w-6 stroke-current", _vm.textColor],
                        icon: "DollarSignIcon",
                      },
                      on: {
                        click: function ($event) {
                          _vm.$router
                            .push("/financial/general-cash")
                            .catch(() => {})
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "starred-page" },
              [
                _c(
                  "vx-tooltip",
                  {
                    attrs: {
                      text: "Veículos",
                      position: "bottom",
                      delay: ".3s",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons-outlined h-6 w-6 p-2 cursor-pointer",
                        style: { color: _vm.textColor },
                        on: {
                          click: function ($event) {
                            _vm.$router.push("/vehicles").catch(() => {})
                          },
                        },
                      },
                      [_vm._v("directions_car")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }