var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.activeUserInfo.name
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(
                  _vm._s(_vm.activeUserInfo.name ? _vm.activeUserInfo.name : "")
                ),
              ]),
              _c("small", [_vm._v("Online")]),
            ]
          ),
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c("div", { staticClass: "con-img ml-3" }, [
                _c("img", {
                  key: "onlineImg",
                  staticClass: "rounded-full shadow-md cursor-pointer block",
                  attrs: {
                    src: _vm.activeUserInfo.photo
                      ? _vm.activeUserInfo.photo.url
                      : _vm.noUserPhoto,
                    alt: "user-img",
                    width: "40",
                    height: "40",
                  },
                }),
              ]),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticStyle: { "min-width": "9rem" } },
                  [
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function ($event) {
                            _vm.$router.push("/pages/profile").catch(() => {})
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "UserIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [_vm._v("Perfil")]),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: {
                          click: function ($event) {
                            _vm.$router.push("/apps/chat").catch(() => {})
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: "MessageSquareIcon",
                            svgClasses: "w-4 h-4",
                            badge: Number.isInteger(
                              _vm.activeUserInfo.unreadChat
                            )
                              ? _vm.activeUserInfo.unreadChat
                              : 0,
                          },
                        }),
                        _c("span", { staticClass: "ml-2" }, [_vm._v("Chat")]),
                      ],
                      1
                    ),
                    _c("vs-divider", { staticClass: "m-1" }),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.logout },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [_vm._v("Sair")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }