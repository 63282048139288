var render = function render(_c, _vm) {
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c("p", [
        _c("span", [_vm._v("COPYRIGHT ©")]),
        _c("span", [_vm._v(_vm._s(new Date().getFullYear()) + " ")]),
        _c(
          "a",
          {
            attrs: {
              href: "https://sigcfc.com.br",
              target: "_blank",
              rel: "nofollow",
            },
          },
          [_vm._v("SIGCFC")]
        ),
        _c("span", { staticClass: "hidden sm:inline-block" }, [
          _vm._v(", Todos os Direitos Reservados"),
        ]),
      ]),
      _c("span", { staticClass: "md:flex hidden items-center" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }