var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-dropdown",
    {
      staticClass: "cursor-pointer",
      attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
    },
    [
      _c(
        "span",
        { staticClass: "cursor-pointer flex items-center i18n-locale" },
        [
          _c("img", {
            staticClass: "h-4 w-5",
            attrs: { src: _vm.i18n_locale_img, alt: _vm.$i18n.locale },
          }),
          _c("span", { staticClass: "hidden sm:block ml-2" }, [
            _vm._v(_vm._s(_vm.getCurrentLocaleData.lang)),
          ]),
        ]
      ),
      _c(
        "vs-dropdown-menu",
        { staticClass: "w-48 i18n-dropdown vx-navbar-dropdown" },
        [
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.updateLocale("en")
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/en.png"),
                  alt: "en",
                },
              }),
              _vm._v("  English"),
            ]
          ),
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.updateLocale("fr")
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/fr.png"),
                  alt: "fr",
                },
              }),
              _vm._v("  French"),
            ]
          ),
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.updateLocale("de")
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/de.png"),
                  alt: "de",
                },
              }),
              _vm._v("  German"),
            ]
          ),
          _c(
            "vs-dropdown-item",
            {
              on: {
                click: function ($event) {
                  return _vm.updateLocale("pt_Br")
                },
              },
            },
            [
              _c("img", {
                staticClass: "h-4 w-5 mr-1",
                attrs: {
                  src: require("@/assets/images/flags/pt_Br.png"),
                  alt: "Portugês",
                },
              }),
              _vm._v("  Portugês"),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }