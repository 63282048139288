<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 ml-3 mr-2" :badge="unreadAlertsTotal" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadAlertsTotal }} {{ unreadAlertsTotal === 1 ? 'Mensagem não lida' : 'Mensagens não lidas' }}</h3>
        <p class="opacity-75">Notificações</p>
      </div>

      <component :is="scrollbarTag" v-if="unreadAlerts.length" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="ntf in unreadAlerts" :key="ntf.id" @click="read(ntf.id)" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex items-start">
              <feather-icon :icon="ntf.icon" :svgClasses="[`text-${ntf.class}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-${ntf.class}`]">{{ ntf.title }}</span>
                <small v-html="ntf.message"></small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.created_at) }}</small>
          </li>
        </ul>
      </component>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">
        <router-link to="/alerts/list"> <span>Ver Todas as Notificações</span> </router-link>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moduleAlert        from '@/store/alert/moduleAlert.js'

export default {
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    user () { return this.$store.state.AppActiveUser },
    unreadAlertsTotal () { return this.$store.state.alert.unreadAlertsTotal },
    unreadAlerts () { return this.$store.state.alert.unreadAlerts }
  },
  methods: {
    elapsedTime (startTime) {
      const x        = new Date(startTime)
      const now      = new Date()
      let timeDiff = now - x
      timeDiff    /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff    = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff    = Math.floor(timeDiff / 60)

      const hours   = Math.round(timeDiff % 24)
      timeDiff    = Math.floor(timeDiff / 24)

      const days    = Math.round(timeDiff % 365)
      timeDiff    = Math.floor(timeDiff / 365)

      const years   = timeDiff

      if (years > 0) {
        return `${years + (years > 1 ? ' Anos ' : ' Ano ')}atrás`
      } else if (days > 0) {
        return `${days + (days > 1 ? ' Dias ' : ' Dia ')}atrás`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hora ')}atrás`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}atrás`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' Seg. atrás' : 'Agora Mesmo')
      }

      return 'Agora Mesmo'
    },
    async read (id) {
      await this.$store.dispatch('alert/markReadAlert', { id, user: this.user })
      if (!this.unreadAlerts.length && this.unreadAlertsTotal > 0) this.fetchUnreadAlerts()
    },
    handleIncoming (alert) {
      alert.user = this.user
      this.$store.commit('alert/INCOMING_ALERT', alert)
    },
    async fetchUnreadAlerts () {
      await this.$store.dispatch('alert/fetchUnreadAlerts')
    }
  },
  mounted () {
    /** AQUI ESCUTO OS EVENTOS DE CHAT DO LARAVEL */
    window.Echo.channel('public').listen('.alert', e => {
      this.handleIncoming(e.alert)
    })
    this.fetchUnreadAlerts()
  },
  created () {
    if (!moduleAlert.isRegistered) {
      this.$store.registerModule('alert', moduleAlert)
      moduleAlert.isRegistered = true
    }
  }
}

</script>

