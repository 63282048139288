var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-auto-suggest" }, [
    _c(
      "div",
      { staticClass: "flex items-center relative" },
      [
        _c("vs-input", {
          ref: "input",
          staticClass: "z-50",
          class: _vm.inputClassses,
          attrs: {
            placeholder: _vm.placeholder,
            "icon-pack": "feather",
            icon: "icon-search",
            "icon-no-border": "",
          },
          on: {
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                return _vm.escPressed.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.suggestionSelected.apply(null, arguments)
              },
            ],
            focus: _vm.updateInputFocus,
            blur: function ($event) {
              return _vm.updateInputFocus(false)
            },
          },
          model: {
            value: _vm.searchQuery,
            callback: function ($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery",
          },
        }),
      ],
      1
    ),
    _c(
      "ul",
      {
        ref: "scrollContainer",
        staticClass:
          "auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-x-hidden",
        class: { hidden: !_vm.inputFocused },
        attrs: { tabindex: "-1" },
        on: {
          mouseenter: function ($event) {
            _vm.insideSuggestions = true
          },
          mouseleave: function ($event) {
            _vm.insideSuggestions = false
          },
          focus: _vm.updateInputFocus,
          blur: function ($event) {
            return _vm.updateInputFocus(false)
          },
        },
      },
      [
        _vm._l(
          _vm.filteredData,
          function (suggestion_list, grp_name, grp_index) {
            return _c(
              "li",
              {
                key: grp_index,
                ref: "grp_list",
                refInFor: true,
                staticClass: "auto-suggest__suggestion-group-container",
              },
              [
                !_vm.hideGroupTitle
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "auto-suggest__suggestion-group-title pt-3 pb-1 px-4",
                      },
                      [_vm._t("group", null, { group_name: grp_name })],
                      2
                    )
                  : _vm._e(),
                _c(
                  "ul",
                  [
                    _vm._l(suggestion_list, function (suggestion, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass:
                            "auto-suggest__suggestion-group__suggestion py-3 px-4 cursor-pointer",
                          class: {
                            "vx-auto-suggest__current-selected":
                              _vm.currentSelected == `${grp_index}.${index}`,
                          },
                          on: {
                            mouseenter: function ($event) {
                              _vm.currentSelected = suggestion
                            },
                            click: _vm.suggestionSelected,
                          },
                        },
                        [_vm._t(grp_name, null, { suggestion: suggestion })],
                        2
                      )
                    }),
                    !suggestion_list.length && _vm.searchQuery
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "auto-suggest__suggestion-group__suggestion py-3 px-4 no-results",
                          },
                          [
                            _vm._t(
                              "noResult",
                              function () {
                                return [
                                  _c("p", [
                                    _vm._v("Nenhum resultado encontrado."),
                                  ]),
                                ]
                              },
                              { group_name: grp_name }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          }
        ),
        _vm.isFetching
          ? _c(
              "li",
              {
                staticClass:
                  "auto-suggest__suggestion-group__suggestion py-3 px-4 is-fetching",
              },
              [_vm._t("isFetching")],
              2
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }